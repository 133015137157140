/**
 * @generated SignedSource<<ee92644486ceb632555aa69e5117773c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductAvailability = "PRODUCT_AVAILABILITY_AVAILABLE_FOR_ORDER" | "PRODUCT_AVAILABILITY_DISCONTINUED" | "PRODUCT_AVAILABILITY_IN_STOCK" | "PRODUCT_AVAILABILITY_OUT_OF_STOCK" | "PRODUCT_AVAILABILITY_PENDING" | "PRODUCT_AVAILABILITY_PREORDER" | "PRODUCT_AVAILABILITY_REMOVED" | "PRODUCT_AVAILABILITY_UNKNOWN" | "%future added value";
export type ProductDetail_ProductLib_Query$variables = {
  companyId: string;
  productId: string;
};
export type ProductDetail_ProductLib_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetail_ProductsLib_Company" | "ProductDetail_ProductsLib_NodeQuery">;
};
export type ProductDetail_ProductLib_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly timezone: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly node: {
    readonly __typename: "Product";
    readonly availability: ProductAvailability;
    readonly brand: string;
    readonly categories: ReadonlyArray<string>;
    readonly collections: ReadonlyArray<string>;
    readonly description: string;
    readonly id: string;
    readonly imageLinks: ReadonlyArray<{
      readonly url: string;
    }>;
    readonly link: string;
    readonly maxPrice: {
      readonly amount: string;
    };
    readonly minPrice: {
      readonly amount: string;
    };
    readonly name: string;
    readonly originId: string;
    readonly originPublishedAt: SerializedDateTime;
    readonly productOptions: ReadonlyArray<{
      readonly name: string;
      readonly values: ReadonlyArray<string>;
    }>;
    readonly tags: ReadonlyArray<string>;
    readonly updated: SerializedDateTime;
    readonly variants: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly inventoryQuantity: number;
          readonly name: string;
        };
      }>;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type ProductDetail_ProductLib_Query = {
  rawResponse: ProductDetail_ProductLib_Query$rawResponse;
  response: ProductDetail_ProductLib_Query$data;
  variables: ProductDetail_ProductLib_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductDetail_ProductLib_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProductDetail_ProductsLib_NodeQuery"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProductDetail_ProductsLib_Company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductDetail_ProductLib_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "productId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availability",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "originId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "originPublishedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brand",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categories",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collections",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductOptionValue",
                "kind": "LinkedField",
                "name": "productOptions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "values",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageLink",
                "kind": "LinkedField",
                "name": "imageLinks",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 50
                  }
                ],
                "concreteType": "ProductVariantConnection",
                "kind": "LinkedField",
                "name": "variants",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductVariantEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductVariant",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "inventoryQuantity",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "variants(first:50)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "minPrice",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "maxPrice",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezone",
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "007752b340ce90b8783b178cd2166877",
    "id": null,
    "metadata": {},
    "name": "ProductDetail_ProductLib_Query",
    "operationKind": "query",
    "text": "query ProductDetail_ProductLib_Query(\n  $companyId: ID!\n  $productId: ID!\n) {\n  ...ProductDetail_ProductsLib_NodeQuery\n  ...ProductDetail_ProductsLib_Company\n}\n\nfragment ProductBreadcrumb_ProductsLib_Product on Product {\n  name\n}\n\nfragment ProductDetail_ProductsLib_Company on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      ...Product_ProductsLib_Company\n    }\n    id\n  }\n}\n\nfragment ProductDetail_ProductsLib_NodeQuery on Query {\n  node(id: $productId) {\n    __typename\n    ... on Product {\n      ...ProductBreadcrumb_ProductsLib_Product\n      ...Product_ProductsLib_Product\n    }\n    id\n  }\n}\n\nfragment Product_ProductsLib_Company on Company {\n  timezone\n}\n\nfragment Product_ProductsLib_Product on Product {\n  ...Product_getProductAvailability_Product\n  name\n  description\n  originId\n  link\n  availability\n  originPublishedAt\n  updated\n  brand\n  tags\n  categories\n  collections\n  productOptions {\n    name\n    values\n  }\n  imageLinks {\n    url\n  }\n  variants(first: 50) {\n    edges {\n      node {\n        id\n        inventoryQuantity\n        name\n      }\n    }\n  }\n  ...getProductPrice_utils_ProductsLib_Product\n}\n\nfragment Product_getProductAvailability_Product on Product {\n  availability\n}\n\nfragment getProductPrice_utils_ProductsLib_Product on Product {\n  minPrice {\n    amount\n  }\n  maxPrice {\n    amount\n  }\n}\n"
  }
};
})();

(node as any).hash = "20942b8189b7cabb1286fe15579f91da";

export default node;
